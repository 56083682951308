// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-gite-template-js": () => import("/opt/build/repo/src/templates/gite-template.js" /* webpackChunkName: "component---src-templates-gite-template-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gites-js": () => import("/opt/build/repo/src/pages/gites.js" /* webpackChunkName: "component---src-pages-gites-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("/opt/build/repo/src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-lieu-js": () => import("/opt/build/repo/src/pages/lieu.js" /* webpackChunkName: "component---src-pages-lieu-js" */),
  "component---src-pages-region-js": () => import("/opt/build/repo/src/pages/region.js" /* webpackChunkName: "component---src-pages-region-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

